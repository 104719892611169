<template>
  <div>
        <section class="error-page section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3 col-12">
                        <!-- Error Inner -->
                        <div class="error-inner">
                            <h1 :class="['text_color_drasam']">404<span>Vaya, lo siento, no podemos encontrar esta página!</span></h1>
                            <p>Estamos trabajando duro para corregir este problema. Espere unos instantes e intente mas tarde nuevamente.</p>
                        </div>
                        <!--/ End Error Inner -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'MesaParteVirtual',
        mounted(){
            document.title = "Pagina no encontrada..!";
        },
    }
</script>

<style scoped>
    .h-alt-hf {
        height: calc(100vh - 15.5625rem);
        width: 100%;
    }
    .fw-500 {
        font-weight: 500 !important;
    }
</style>